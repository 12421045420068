global.jQuery = require('jquery');
const tilt = require('tilt.js');
const picturefill = require('picturefill');
const animsition = require('animsition');
const imagesLoaded = require('imagesloaded');
// const ScrollMagic = require('scrollmagic');
// ScrollMagic plugins
// require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');
// require('scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic');
// require('scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators');

// App
let Onward = {};

Onward.selectors = {
  app: '.js-app-container',
  cardDeck: '.js-card-deck',
  header: '.js-header',
  tilt: '.js-tilt'
};

Onward.$refs = Object.keys(Onward.selectors).reduce((obj, current) => {
  obj[current] = jQuery(Onward.selectors[current]);
  return obj;
}, {});

Onward.animation = {
  selectors: {
    fade: '.animate--fade',
    slideFade: '.animate--slide-fade'
  },
  duration: {
    short: 0.15,
    medium: 0.35,
    long: 0.85
  },
  // controller: new ScrollMagic.Controller(),
  scenes: {}
};

Onward.init = function() {
  console.log('init ::', this);
  // this.initPreload();
  this.setupImagePolyfill();
  this.setupPageTransitions();
  // this.setupScrollAnimations();
  this.setupTilt();
};

Onward.initPreload = function() {
  console.log('initPreload');
  imagesLoaded.makeJQueryPlugin(jQuery);
};

Onward.setupImagePolyfill = function() {
  console.log('setupImagePolyfill');
  picturefill();
};

Onward.setupPageTransitions = function() {
  console.log('setupPageTransitions');
  this.$refs.app
    .animsition({
      inClass: 'fade-in',
      outClass: 'fade-out',
      inDuration: 350,
      outDuration: 350,
      linkElement: 'a:not([target="_blank"]):not([href^="#"]):not([href^="mailto"]):not([download])',
      loading: true,
      loadingClass: 'onward-loading',
      onLoadEvent: false
    })
    .imagesLoaded()
    .always(() => {
      console.log('imagesLoaded');
      this.$refs.app.animsition('in');
    });
};

Onward.setupScrollAnimations = function() {
  console.log('setupScrollAnimations');

  const aboutAnimation = new TimelineMax()
    .fromTo(this.animation.selectors.slideFade, this.animation.duration.long, { opacity: 0, y: 100 }, { opacity: 1, y: 0 })
    .fromTo(this.animation.selectors.fade, this.animation.duration.short, { opacity: 0 }, { opacity: 1 }, '-=0.25');

  const aboutScene = new ScrollMagic.Scene({
    triggerElement: this.selectors.aboutBlock,
    triggerHook: 0.5,
    offset: 300
  })
    .setTween(aboutAnimation)
    .addIndicators()
    .addTo(this.animation.controller);
};

Onward.setupTilt = function() {
  this.$refs.tilt.tilt({
    maxTilt: 5,
    perspective: 1500,
    scale: 1.01
  });
};

// Init app.
Onward.init();
